<style lang="scss">
  .manage-group-btn {
    float:right;
    margin-top: -10px;
  }
</style>
<!--eslint-disable-->
<template>
  <div class="users-page">
    <section v-if="!loading">
      <div v-if="group" class="row">
        <div class="col-12">

          <b-card-actions :noActions=true :title="group.name + ' (v' + group.version + ')'" class="">

              Updating this group will also update any device currently sat in the Enrollment Group.
              The default pin for a device in the enrollment group is <span class="badge badge-secondary">a0000</span>


              <b-dropdown id="dropdown-grouped" variant="primary" right text="Manage" class="manage-group-btn" >
                <b-dropdown-group id="dropdown-group-1" header="Group Actions">
                  <b-dropdown-item v-if="$can('Update', 'Group')" :to="{ name: 'enrollment-edit', params: { uid: group.uid } }">
                    Update Group
                  </b-dropdown-item>
                </b-dropdown-group>
              </b-dropdown>

          </b-card-actions>
          <div v-if="!group.is_secure && group.is_secure != null" class="alert alert-warning p-2">
            <i class="fas fa-exclamation-triangle" />
            WARNING The current group settings are not secure
            <i class="fas fa-exclamation-triangle" />
          </div>
          <b-tabs content-class="my-2">
            <b-tab title="Devices">
              <group-devices />
            </b-tab>
            <b-tab title="Networks">
              <group-networks :group="group"></group-networks>
            </b-tab>
            <b-tab title="Activities" v-if="$can('Read', 'Activity')">
              <model-activities model="group" :uid="group.uid"></model-activities>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div v-else>No Groups found</div>

      <b-modal id="modal-sync-group" title="" hide-footer>
        <warning-modal v-if="group" title="Are you sure you wish to sync the policies for this group?"
             ok-variant="success"
             @ok="syncGroup(group)"
             @close="closeModals()"
        />
      </b-modal>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading group"></b-spinner>
    </section>

  </div>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
  import EnrollmentService from '../../services/EnrollmentService';
  import WarningModal from '../../components/modals/WarningModal.vue';
  import GroupNetworks from './sections/GroupNetworks.vue';
  import GroupDevices from './sections/GroupDevices.vue';
  import ModelActivities from '../activities/ModelActivities.vue';

  export default {
    name: 'GroupView',
    components: {
      WarningModal,
      GroupNetworks,
      GroupDevices,
      ModelActivities,
      BCardActions,
    },
    data() {
      return {
        loading: true,
        group: null,
      };
    },
    mounted() {
      this.getGroup();
    },
    methods: {
      refreshGroup() {
        this.closeModals();
        this.getGroup();
      },
      closeModals() {
        this.$bvModal.hide('modal-update-group');
        this.$bvModal.hide('modal-qr');
      },
      getGroup() {
        this.loading = true;
        EnrollmentService.getEnrollmentGroup().then(res => {
          this.group = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get group, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
