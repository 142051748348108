<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="row p-2" v-if="$can('Create', 'Group')">
        <b-col cols="10">
          <h3>Enrolled Devices</h3>
          Devices here are ready to be assigned to Owners. The devices will be set up with the networks as specified by the Enrollment Group.
        </b-col>
        <b-col cols="2">
          <b-button @click="$bvModal.show('modal-qr')" class="btn float-right" variant="success" v-if="$can('Create', 'Device')" >Enrol Device</b-button>
        </b-col>
      </div>

      <div class="table-responsive">
        <b-table class="data-table" hover :items="devices" :fields="headers" :current-page="currentPage" :per-page="0" show-empty>

          <template #cell(updated_at)="data">
            {{data.item.updated_at | formatDateTime}}
          </template>
          <template #cell(is_device_secure)="data">
            {{data.item.is_device_secure | boolToYes}}

            <span v-if="data.item.policy_override" v-b-tooltip.hover="'WARNING The policy on this device has been isolated. It may not receive further updates'">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
          </template>

          <template #cell(applied_policy_version)="data">
            {{data.item.applied_policy_version}}
          </template>


          <template #cell(status)="data">
            <b-badge variant="success">{{ data.item.status }}</b-badge>
          </template>

          <template #cell(policy_compliant)="data">
            {{data.item.policy_compliant | boolToYes}}
          </template>
          <template #cell(hardware_info_serial_number)="data">
            <router-link :to="{ name: 'device-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap" :class="{ 'text-secondary strike-through': data.item.deleted_at }">
              {{data.item.hardware_info_serial_number}}
            </router-link>
          </template>

          <template #cell(applied_policy_version)="data">
            <PolicyInfo :group-version="data.item.group_policy_version" :applied-version="data.item.applied_group_policy_version"/>
          </template>

          <template #empty="data">
            No devices in the Enrolment Group
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreDevices" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <b-modal id="modal-qr" title="Enroll a Device" hide-footer>
      <enroll-device @close="closeModals()"></enroll-device>
    </b-modal>

  </div>

</template>
<!--eslint-enable-->

<script>
  import EnrollDevice from './EnrollDevice.vue';
  import DeviceService from "@/services/DeviceService";
  import PolicyInfo from "@/views/devices/viewDevice/sections/PolicyInfo.vue";

  export default {
    components: {
      PolicyInfo,
      EnrollDevice
    },
    data() {
      return {
        devices: [],
        headers: [
          {
            key: 'hardware_info_serial_number',
            label: 'Serial Number',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'hardware_info_brand',
            label: 'Brand',
          },
          {
            key: 'is_device_secure',
            label: 'Secure',
          },
          {
            key: 'policy_compliant',
            label: 'Compliant',
          },
          {
            key: 'applied_policy_version',
            label: 'Policy Version',
          },
          {
            key: 'api_level',
            label: 'API Level',
          },
          {
            key: 'updated_at',
            label: 'Updated At',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
          search: '',
          secure: '',
          encrypted: '',
          deleted: false,
          group_uid: 'enrollment',
        },
        total_count: 0,
      };
    },
    mounted() {
      this.setFilters();
      this.getDevices();
    },
    methods: {
      closeModals() {
        this.$bvModal.hide('modal-qr');
      },
      getMoreDevices(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getDevices();
      },
      setFilters() {
        this.filters.secure = this.$route.query.secure || ''
        this.filters.encrypted = this.$route.query.encrypted || ''
        this.filters.deleted = this.$route.query.deleted || false
      },
      getDevices() {
        DeviceService.getDevices(this.filters).then(res => {
          this.devices = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get devices, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      clearFilters() {
        this.filters = {
          page: 0,
          page_size: 25,
          search: '',
        };

        this.getMoreDevices(1);
      },
    },
  };
</script>
