<style lang="scss"></style>

<template>
  <div>
    <section v-if="loading" class="text-center m-5">
      <b-spinner variant="primary" label="Configuring Policy" />
    </section>

    <section v-else>
      <section v-if="!qrCode">
        <p v-if="errMessage" class="text-danger">{{ errMessage }}</p>
        <p v-else>Cannot get QR Code</p>
      </section>
      <section v-else>
        <h2 class="text-center mt-2 mb-1">{{ qrCode.name }}</h2>
        <img class="d-flex mx-auto" :src="qrCode.qr_code" alt="">
        <p class="text-center">Expires: {{ qrCode.Expires | formatDateTime }}</p>
      </section>
    </section>

    <b-button block class="mr-2" @click="$emit('close', true)">Close</b-button>
  </div>
</template>

<script>
  import DevicesService from '@/services/DeviceService';

  export default {
    components: {},
    data() {
      return {
        loading: true,
        qrCode: null,
        errMessage: '',
      };
    },
    mounted() {
      this.getQrCodeEnrollment();
    },
    methods: {
      getQrCodeEnrollment() {
        this.loading = true;
        this.errMessage = '';
        DevicesService.getQrCodeEnrollment().then(res => {
          this.qrCode = res.data;
        }).catch(err => {
          const res = err.response
          this.errMessage = 'Could not get QR code, please refresh and try again'

          if (res && res.data.error) {
            this.errMessage = res.data.error
          }

          this.$emit('refresh', true);
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
