import axios from '@axios';

const baseUrl = '/enrollment-group';

export default {
  getEnrollmentGroup() {
    return axios.get(`${baseUrl}`);
  },
  updateGroup(groupDto) {
    return axios.put(`${baseUrl}`, groupDto);
  },
};
