<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <b-card-actions action-collapse title="Networks" ref="networkCard" class="" no-body>

    <b-table
      class="data-table"
      hover
      :items="group.networks"
      :fields="headers">
    </b-table>

    <p class="m-2" v-if="group.networks.length === 0">No Networks</p>

  </b-card-actions>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

  export default {
    components: {
      BCardActions,
    },
    props: {
      group: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        headers: [
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'security',
            label: 'security',
          },
          {
            key: 'ssid',
            label: 'SSID',
          },
        ],
      };
    },
    mounted() {

    },
    methods: {

    },
  };
</script>
